import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Layout from '../components/Layout';
import { HTMLContent } from '../components/Content';
import Hero from '../components/Hero/Hero';
import styles from '../styles/basicpage.module.scss';

export const BestPracticesTemplate = ({
  seo,
  title,
  date,
  content,
  location,
}) => (
  <Layout seo={seo} location={location}>
    <div className={cx(styles.basicPage)}>
      <Hero classNames={['grey']}>
        <div className={cx('text')}>
          <h1>{ title }</h1>
          <h4>Last updated {date}</h4>
        </div>
      </Hero>
      <div className={cx('container')}>
        <HTMLContent content={content} />
      </div>
    </div>
  </Layout>
);

BestPracticesTemplate.propTypes = {
  title: PropTypes.string.isRequired,
};

const BestPracticesPage = ({ location, data }) => {
  const { markdownRemark: { frontmatter, html } } = data;
  return (
    <BestPracticesTemplate
      location={location}
      {...frontmatter}
      content={html}
    />
  );
};

BestPracticesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default BestPracticesPage;

export const BestPracticesPageQuery = graphql`
  query BestPracticesPage {
    markdownRemark(frontmatter: { name: { eq: "bestPractices" } }) {
      frontmatter {
        seo {
          title
          description
        }
        title
        date(formatString: "MMMM DD, YYYY")
      }
      html
    }
  }
`;
